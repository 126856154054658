import * as React from 'react'
import { tw } from 'utils/classnames'
import { useTranslations } from 'next-intl'
import { useIsLoggedInQuery } from 'auth/hooks'
import { AskCfGetTopicsQuery, useAskCfGetUserDigestStatusQuery } from 'codegen/generated/ask-cf'

import {
  ChevronDownIcon,
  ChevronUpIcon,
  FireIcon,
  PsychologyIcon,
  TopicsIcon,
  TrendingUpIcon,
} from '@ask-cf/assets/icons'
import { TopicsList } from '../topics-list'
import { BannerContext } from '@ask-cf/contexts/banner-context'
import { FeedSortButtons } from '../feed/components/feed-sort-buttons'
import { FeedSortingOption, useFeedSorting } from '@ask-cf/contexts/feed-sorting-context'
import { ASK_CF_FF_DIGEST_SIGNUP_BANNER, ASK_CF_FF_FEED_SORT } from '@ask-cf/config'
import { useIsMainPage } from '@ask-cf/hooks/use-is-main-page'

export function MobileMenu({
  showTopics = true,
  position = 'fixed',
  topicsQuery,
}: {
  showTopics?: boolean
  position?: 'fixed' | 'relative'
  topicsQuery?: AskCfGetTopicsQuery
}) {
  const { data: isLoggedIn } = useIsLoggedInQuery()
  const [expandedSectionKey, setExpandedSectionKey] = React.useState<
    'topics' | 'sort-buttons' | null
  >(null)
  const topicsExpanded = expandedSectionKey === 'topics'
  const sortButtonsExpanded = expandedSectionKey === 'sort-buttons'
  React.useEffect(() => {
    const closeWindow = () => setTimeout(() => setExpandedSectionKey(null), 10)
    if (topicsExpanded || sortButtonsExpanded) {
      setTimeout(() => window.addEventListener('click', closeWindow), 10)
      return () => window.removeEventListener('click', closeWindow)
    }
  }, [topicsExpanded, sortButtonsExpanded])
  const { data: isSubscribed, isLoading: isSubscribedLoading } = useAskCfGetUserDigestStatusQuery(
    undefined,
    {
      select: data => data?.askCFGetUserDigestStatus?.subscribed,
    },
  )
  const { bannerActive } = React.useContext(BannerContext)
  const isMainPage = useIsMainPage()
  const t = useTranslations('ask_cf.components.mobile_menu')
  const heightClass = tw('h-[52px]', topicsExpanded && 'h-[442px]', sortButtonsExpanded && 'h-auto')

  const topClass =
    isSubscribed || !ASK_CF_FF_DIGEST_SIGNUP_BANNER || isSubscribedLoading
      ? 'top-[197px]'
      : 'top-[243px]'

  const positionClass = position === 'fixed' ? `fixed left-4 right-4 z-[21] ${topClass}` : 'w-full'
  return (
    <div
      className={tw(
        'rounded-2 flex flex-col bg-white xl:hidden',
        'transition-height z-10 overflow-hidden duration-500',
        heightClass,
        positionClass,
        topicsExpanded || sortButtonsExpanded ? 'shadow-mobile-menu' : '',
      )}
    >
      <div className="flex h-[52px] w-full shrink-0 items-center justify-between">
        {ASK_CF_FF_FEED_SORT && isLoggedIn ? (
          <div className={tw((bannerActive || isMainPage) && 'rounded-2 w-full bg-white')}>
            <div className={tw('flex flex-row px-2 py-1', !showTopics && 'w-full')}>
              <button
                onClick={() =>
                  setExpandedSectionKey(expandedSectionKey =>
                    expandedSectionKey !== 'sort-buttons' ? 'sort-buttons' : null,
                  )
                }
                id="sort-options-toggle"
                className={tw(
                  'flex flex-row items-center gap-1 p-1 outline-none',
                  !showTopics && 'w-full justify-between',
                  sortButtonsExpanded && 'bg-background-primary rounded-full',
                )}
                aria-label={t('sort_toggle_aria_label')}
              >
                <CurrentSortOptionLabel />

                {sortButtonsExpanded ? (
                  <ChevronUpIcon className="text-text-blue" />
                ) : (
                  <ChevronDownIcon className="text-text-blue" />
                )}
              </button>
            </div>
          </div>
        ) : null}
        {showTopics ? (
          <div className={tw(bannerActive && 'rounded-b-2 w-full bg-white')}>
            <div className="flex flex-row px-2 py-1">
              <button
                onClick={() =>
                  setExpandedSectionKey(expandedSectionKey =>
                    expandedSectionKey !== 'topics' ? 'topics' : null,
                  )
                }
                id="topics-toggle"
                className={tw(
                  'ml-auto flex flex-row items-center gap-1 p-1 outline-none',
                  topicsExpanded && 'bg-background-primary rounded-full',
                )}
                aria-label={t('topics_toggle_aria_label')}
              >
                <TopicsIcon />

                {topicsExpanded ? <ChevronUpIcon /> : <ChevronDownIcon />}
              </button>
            </div>
          </div>
        ) : null}
      </div>
      {sortButtonsExpanded && ASK_CF_FF_FEED_SORT && isLoggedIn ? (
        <FeedSortButtons onItemClick={() => setExpandedSectionKey(null)} />
      ) : null}
      {showTopics && topicsExpanded && topicsQuery ? (
        <TopicsList onItemClick={() => setExpandedSectionKey(null)} topicsQuery={topicsQuery} />
      ) : null}
    </div>
  )
}

function CurrentSortOptionLabel() {
  const t = useTranslations('ask_cf.components.feed')
  const { activeSortOption } = useFeedSorting()
  const Icon = resolveIcon(activeSortOption)

  const translationKey = activeSortOption.replace('-', '_')
  return (
    <div className="text-text-blue flex items-center gap-1 text-xs font-medium">
      <Icon />
      <span className="leading-[20px]">{t(translationKey)}</span>
    </div>
  )
}
function resolveIcon(activeSortOption: FeedSortingOption) {
  if (activeSortOption === 'your-feed') {
    return TrendingUpIcon
  }
  if (activeSortOption === 'discovery-feed') {
    return FireIcon
  }
  return PsychologyIcon
}
