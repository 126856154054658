'use client'

import * as React from 'react'
import { useLocale, useTranslations } from 'next-intl'
import { motion } from 'framer-motion'
import { tw } from 'utils/classnames'
import { AskCfGetTopicsQuery } from 'codegen/generated/ask-cf'
import { SkeletonItem } from 'components/skeleton-item'

import { Link } from '@ask-cf/components/link'
import { ChevronRightIcon } from '@ask-cf/assets/icons'
import { StyledLink } from '../styled-link'
import { ROUTES } from '@ask-cf/constants/app-routes'
import { SUPPORT_TOPIC_SLUG, TOPICS_LIST } from '@ask-cf/constants/topics'
import { formatNumber } from '@ask-cf/utils/math'
import { mergeTrendingTopics } from '@ask-cf/utils/topics'
import { SidebarTopicsListItemSkeleton } from '../topics-skeleton/components/sidebar-topics-list-item-skeleton'

export function TopicsList({
  onItemClick,
  topicsQuery,
}: {
  onItemClick?: () => void
  topicsQuery: AskCfGetTopicsQuery
}) {
  const t = useTranslations('ask_cf.components.topics_list')
  const locale = useLocale()
  const data = topicsQuery.askCFGetTopics

  const topicsListForSidebar = React.useMemo(() => {
    return data?.topics ? mergeTrendingTopics(TOPICS_LIST, data?.topics) : []
  }, [data?.topics])

  return (
    <div
      id="topics"
      className={tw(
        'rounded-2 w-full bg-white',
        'flex max-h-[391px] flex-col gap-1 px-4 pt-2 sm:px-4 xl:max-h-[876px] xl:w-[256px] xl:gap-4 xl:p-4',
      )}
    >
      <>
        <p id="topics-title" className="text-text-secondary text-xs font-medium xl:text-sm">
          {t('trending_topics')}
        </p>
        <div
          id="topics-list"
          className="box-border flex h-[316px] flex-col flex-wrap gap-6 pt-3 xl:h-full xl:gap-4 xl:pb-4 xl:pt-0"
        >
          {topicsListForSidebar.map(topic => (
            <SidebarTopicCard {...topic} key={topic.id} />
          ))}
        </div>
        <div className="mt-3 flex min-h-[26px] w-full justify-center xl:mt-0">
          <StyledLink
            href={ROUTES.ALL_TOPICS('', locale)}
            className="text-content-text-link mb-3 flex flex-row items-center text-xs font-medium xl:text-sm"
            onClick={onItemClick}
            aria-label={t('view_all_topics_link')}
          >
            {t('view_all_topics_link')}
            <ChevronRightIcon className="h-4 w-4" />
          </StyledLink>
        </div>
      </>
    </div>
  )
}

//TODO: Fix any when we have the correct type for question frm BE
/* eslint-disable @typescript-eslint/no-explicit-any */
export function SidebarTopicCard(props: any) {
  const locale = useLocale()
  const t = useTranslations('ask_cf.components.topics_list')
  const { id, title, questionCount, icon, bgColor, iconColor, slug, isLoading } = props
  const isSupportTopic = slug === SUPPORT_TOPIC_SLUG
  const TopicContainer = ({ children }: React.PropsWithChildren) =>
    isSupportTopic ? (
      <div className="flex h-[40px] flex-row gap-2 xl:h-[60px] xl:w-full xl:gap-4">{children}</div>
    ) : (
      <motion.div whileTap={{ scale: 0.9 }} whileHover={{ scale: 1.03 }} id={`topic-card-${id}`}>
        <Link
          className="group flex h-[40px] cursor-pointer flex-row gap-2 xl:h-[60px] xl:w-full xl:gap-4"
          href={ROUTES.TOPIC_DETAILS(slug, locale)}
        >
          {children}
        </Link>
      </motion.div>
    )
  return (
    <TopicContainer>
      {isLoading ? (
        <SidebarTopicsListItemSkeleton />
      ) : (
        <>
          <div
            id={`topic-card-${id}-icon`}
            className={tw('rounded-2 aspect-[1] p-2 xl:p-[18px]', bgColor, iconColor)}
          >
            {icon}
          </div>
          <div
            id={`topic-card-${id}-details`}
            className="flex h-auto flex-col justify-center gap-1"
          >
            <p
              id={`topic-card-${id}-title`}
              className={tw(
                'text-text-secondary font-medium leading-5 min-[375px]:text-sm',
                'text-xxs',
                'group-hover:text-text-hover',
              )}
            >
              {title}
            </p>
            {!isSupportTopic ? (
              props.isQuestionCountLoading ? (
                <SkeletonItem className="hidden h-[20px] w-[80px] min-[375px]:block" />
              ) : (
                <p
                  id={`topic-card-${id}-questionCount`}
                  className="text-text-quinary hidden text-xs font-medium leading-4 min-[375px]:block"
                >
                  {formatNumber(questionCount)} {t('questions_label.questions')}
                </p>
              )
            ) : null}
          </div>
        </>
      )}
    </TopicContainer>
  )
}
