'use client'
import * as React from 'react'
import ReactDOM from 'react-dom'
import {
  AskCfDiscoveryFeedQuery,
  AskCfGetTopicsQuery,
  useInfiniteAskCfDiscoveryFeedQuery,
  useInfiniteAskCfFollowingFeedQuery,
} from 'codegen/generated/ask-cf'
import { InfiniteData } from '@tanstack/react-query'
import { useTranslations } from 'next-intl'
import Image from 'next/image'
import { tw } from 'utils/classnames'

import DiscoverMorePostsImage from './assets/discover-more-posts.svg'
import { Feed, FeedPostSkeletons } from '@ask-cf/components/feed'
import { MobileMenu } from '@ask-cf/components/mobile-menu'
import { FeedSortingProvider, useFeedSorting } from '@ask-cf/contexts/feed-sorting-context'
import { RightSidebar } from '@ask-cf/components/right-sidebar'
import { LFS_URL } from '@ask-cf/config'
import { Post } from '@ask-cf/components/feed/models'

export function MainPageContent({
  initialPosts,
  children,
  topicsQuery,
}: React.PropsWithChildren<{
  initialPosts: InfiniteData<AskCfDiscoveryFeedQuery>
  topicsQuery: AskCfGetTopicsQuery
}>) {
  return (
    <FeedSortingProvider>
      <MainPageContentBody initialPosts={initialPosts} topicsQuery={topicsQuery}>
        {children}
      </MainPageContentBody>
    </FeedSortingProvider>
  )
}

function MainPageContentBody({
  initialPosts,
  children,
  topicsQuery,
}: React.PropsWithChildren<{
  initialPosts: InfiniteData<AskCfDiscoveryFeedQuery>
  topicsQuery: AskCfGetTopicsQuery
}>) {
  const [jsEnabled, setJsEnabled] = React.useState(false)

  React.useEffect(() => {
    setJsEnabled(true)
  }, [])

  // @ts-expect-error
  ReactDOM.preconnect(LFS_URL)
  const { activeSortOption } = useFeedSorting()
  const discoveryFeedQuery = useInfiniteAskCfDiscoveryFeedQuery(
    {
      input: {
        currentPage: 1,
        itemsPerPage: 5,
      },
    },
    {
      initialData: jsEnabled ? undefined : initialPosts,
      getNextPageParam(_lastPage, allPages) {
        if (
          (_lastPage?.askCFDiscoveryFeed?.pagination?.currentPage || 0) >=
          (_lastPage?.askCFDiscoveryFeed?.pagination?.totalPages || 0)
        ) {
          return undefined
        }

        return {
          input: {
            currentPage: allPages.length + 1,
            itemsPerPage: 5,
          },
        }
      },
    },
  )

  const followersFeedQuery = useInfiniteAskCfFollowingFeedQuery(
    {
      input: {
        currentPage: 1,
        itemsPerPage: 5,
      },
    },
    {
      getNextPageParam(_lastPage, allPages) {
        if (
          (_lastPage?.askCFFollowingFeed?.pagination?.currentPage || 0) >=
          (_lastPage?.askCFFollowingFeed?.pagination?.totalPages || 0)
        ) {
          return undefined
        }

        return {
          input: {
            currentPage: allPages.length + 1,
            itemsPerPage: 5,
          },
        }
      },
    },
  )

  const activeQuery = activeSortOption === 'your-feed' ? followersFeedQuery : discoveryFeedQuery
  const activePosts =
    activeSortOption === 'your-feed'
      ? (followersFeedQuery.data?.pages
          .map(page => page.askCFFollowingFeed?.questions)
          .flat()
          .filter(Boolean) as Post[])
      : (discoveryFeedQuery.data?.pages
          .map(page => page.askCFDiscoveryFeed?.questions)
          .flat()
          .filter(Boolean) as Post[])

  const isYourFeedAndEmpty =
    !activeQuery.isLoading && !activePosts?.length && activeSortOption === 'your-feed'
  const isYourFeedAndNoMorePosts =
    !activeQuery.isLoading &&
    activePosts?.length &&
    !activeQuery.hasNextPage &&
    activeSortOption === 'your-feed'

  return (
    <div className="flex h-full w-full flex-col items-start pb-6 pt-11">
      <div className="flex w-full flex-row gap-6">
        <div className="flex w-full flex-col gap-6 xl:flex-row">
          <MobileMenu topicsQuery={topicsQuery} />
          {children}
          <div className="w-full lg:flex-grow" id="main-content">
            <noscript>
              <Feed
                posts={activePosts || []}
                nextPage={activeQuery.fetchNextPage}
                loading={
                  activeQuery.isLoading ||
                  activeQuery.isFetchingNextPage ||
                  activeQuery.isRefetching
                }
                hasMore={activeQuery.hasNextPage}
              />
            </noscript>
            {jsEnabled ? (
              <Feed
                posts={activePosts || []}
                nextPage={activeQuery.fetchNextPage}
                loading={
                  activeQuery.isLoading ||
                  activeQuery.isFetchingNextPage ||
                  activeQuery.isRefetching ||
                  !jsEnabled
                }
                hasMore={activeQuery.hasNextPage}
              />
            ) : (
              <FeedPostSkeletons />
            )}
            {isYourFeedAndEmpty ? <EmptyStateYourFeed /> : null}
            {isYourFeedAndNoMorePosts ? <NoMoreYourFeedPostsMessage /> : null}
          </div>
        </div>
        <RightSidebar />
      </div>
    </div>
  )
}

const EmptyStateYourFeed = () => {
  const t = useTranslations('ask_cf.pages.root')
  return <YourFeedCTA headerText={t('no_followed_users_header')} />
}

const NoMoreYourFeedPostsMessage = () => {
  const t = useTranslations('ask_cf.pages.root')
  return <YourFeedCTA headerText={t('no_more_posts_from_followed_users_header')} className="mt-4" />
}

const YourFeedCTA = ({ headerText, className }: { headerText: string; className?: string }) => {
  const t = useTranslations('ask_cf.pages.root')
  const buttonLabel = t('your_feed_cta_button_label')
  const { setActiveSortOption } = useFeedSorting()

  const moveToDiscoveryFeed = () => {
    window?.scrollTo(0, 0)
    setActiveSortOption('discovery-feed')
  }
  return (
    <div
      className={tw(
        'rounded-2 flex flex-col bg-white px-6 pb-[25px] pt-4 text-sm leading-[19.6px]',
        className,
      )}
    >
      <div className="mb-2">
        {headerText}{' '}
        <button
          className="text-content-text-link hover:text-text-hover inline bg-transparent text-sm leading-[19.6px]"
          onClick={moveToDiscoveryFeed}
        >
          {t('discover_feed')}
        </button>
        .
      </div>
      <div className="mb-[19px] h-[1px] w-full bg-gray-400" />
      <button
        onClick={moveToDiscoveryFeed}
        className="bg-background-primary rounded-2 flex w-full flex-col items-center gap-1 py-2 text-sm font-medium leading-5 text-gray-600"
      >
        <Image
          alt={buttonLabel}
          src={DiscoverMorePostsImage.src}
          className={'h-[65px] w-[72px]'}
          width="72"
          height="65"
        />
        <span>{buttonLabel}</span>
      </button>
    </div>
  )
}
